export interface CarDataset {
	[index: number]: string;
}
export interface CategoryDataset {
	[index: string]: string;
}
export type MotorcycleDataset = CarDataset;

export interface PlateDataset {
	Car: CarDataset;
	Motorcycle: MotorcycleDataset;
	Category: CategoryDataset;
}

export const plateDataset: PlateDataset = {
	Car: {
		10: "تهران",
		11: "تهران",
		12: "خراسان رضوی",
		13: "اصفحان",
		14: "خوزستان",
		15: "آذربایجان شرقی",
		16: "قم",
		17: "آذربایجان غربی",
		18: "همدان",
		19: "کرمانشاه",
		20: "تهران",
		21: "تهران - البرز",
		22: "تهران",
		23: "اصفحان",
		24: "خوزستان",
		25: "آذربایجان شرقی",
		26: "خراسان شمالی",
		27: "آذربایجان غربی",
		28: "همدان",
		29: "کرمانشاه",
		30: "تهران - البرز",
		31: "لرستان",
		32: "خراسان شمالی - خراسان جنوبی - خراسان رضوی",
		33: "تهران",
		34: "خوزستان",
		35: "آذربایجان شرقی",
		36: "خراسان رضوی",
		37: "آذربایجان غربی",
		38: "تهران - البرز",
		40: "تهران",
		41: "لرستان",
		42: "خراسان رضوی",
		43: "اصفحان",
		44: "تهران",
		45: "یزد",
		46: "گیلان",
		47: "مرکزی",
		48: "بوشهر",
		49: "کهگیلویه و بویراحمد",
		51: "کردستان",
		52: "خراسان جنوبی",
		53: "اصفحان",
		55: "تهران",
		56: "گیلان",
		57: "مرکزی",
		58: "بوشهر",
		59: "گلستان",
		61: "کردستان",
		62: "مازندران",
		63: "فارس",
		64: "یزد",
		65: "کرمان",
		66: "تهران",
		67: "اصفحان",
		68: "البرز",
		69: "گلستان",
		71: "چهارمحال و بختیاری",
		72: "مازندران",
		73: "فارس",
		74: "خراسان رضوی",
		75: "کرمان",
		76: "گیلان",
		77: "تهران",
		78: "تهران - البرز",
		79: "قزوین",
		81: "چهارمحال و بختیاری",
		82: "مازندران",
		83: "فارس",
		84: "هرمزگان",
		85: "سیستان و بلوچستان",
		86: "سمنان",
		87: "زنجان",
		88: "تهران",
		89: "قزوین",
		91: "اردبیل",
		92: "مازندران",
		93: "فارس",
		94: "هرمزگان",
		95: "سیستان و بلوچستان",
		96: "سمنان",
		97: "زنجان",
		98: "ایلام",
		99: "تهران",
	},
	Motorcycle: {
		111: "مرکز تهران",
		112: "مرکز تهران",
		113: "مرکز تهران",
		114: "مرکز تهران",
		115: "مرکز تهران",
		116: "مرکز تهران",
		117: "مرکز تهران",
		118: "مرکز تهران",
		119: "مرکز تهران",
		120: "مرکز تهران",
		121: "مرکز تهران",
		122: "مرکز تهران",
		123: "مرکز تهران",
		124: "مرکز تهران",
		125: "مرکز تهران",
		126: "مرکز تهران",
		127: "مرکز تهران",
		128: "مرکز تهران",
		129: "مرکز تهران",
		130: "مرکز تهران",
		131: "مرکز تهران",
		132: "مرکز تهران",
		133: "مرکز تهران",
		134: "مرکز تهران",
		135: "مرکز تهران",
		136: "مرکز تهران",
		137: "مرکز تهران",
		138: "مرکز تهران",
		319: "البرز و شهرستان های تهران",
		321: "البرز و شهرستان های تهران",
		322: "البرز و شهرستان های تهران",
		323: "البرز و شهرستان های تهران",
		324: "البرز و شهرستان های تهران",
		371: "آذربایجان غربی",
		372: "آذربایجان غربی",
		373: "آذربایجان غربی",
		374: "آذربایجان غربی",
		375: "آذربایجان غربی",
		376: "آذربایجان غربی",
		377: "آذربایجان غربی",
		391: "آذربایجان شرقی",
		392: "آذربایجان شرقی",
		393: "آذربایجان شرقی",
		394: "آذربایجان شرقی",
		395: "آذربایجان شرقی",
		396: "آذربایجان شرقی",
		397: "آذربایجان شرقی",
		442: "اردبیل",
		443: "اردبیل",
		461: "کردستان",
		462: "کردستان",
		479: "زنجان",
		481: "زنجان",
		482: "زنجان",
		498: "همدان",
		499: "همدان",
		511: "همدان",
		514: "کرمانشاه",
		515: "کرمانشاه",
		516: "کرمانشاه",
		517: "کرمانشاه",
		523: "قزوین",
		524: "قزوین",
		525: "قزوین",
		531: "مرکزی",
		532: "مرکزی",
		533: "مرکزی",
		534: "مرکزی",
		535: "مرکزی",
		538: "لرستان",
		539: "لرستان",
		540: "لرستان",
		541: "لرستان",
		542: "لرستان",
		543: "لرستان",
		547: "ایلام",
		555: "چهارمحال و بختیاری",
		561: "خوزستان",
		562: "خوزستان",
		563: "خوزستان",
		564: "خوزستان",
		565: "خوزستان",
		566: "خوزستان",
		567: "خوزستان",
		568: "خوزستان",
		569: "خوزستان",
		571: "کهگیلویه و بویراحمد",
		578: "گیلان",
		579: "گیلان",
		581: "گیلان",
		582: "گیلان",
		586: "مازندران",
		587: "مازندران",
		588: "مازندران",
		589: "مازندران",
		596: "گلستان",
		597: "گلستان",
		611: "قم",
		612: "قم",
		613: "قم",
		614: "قم",
		637: "یزد",
		638: "یزد",
		639: "یزد",
		641: "یزد",
		642: "یزد",
		643: "یزد",
		687: "فارس",
		688: "فارس",
		689: "فارس",
		691: "فارس",
		692: "فارس",
		693: "فارس",
		694: "فارس",
		695: "فارس",
		696: "فارس",
		697: "فارس",
		751: "سمنان",
		752: "سمنان",
		753: "سمنان",
		761: "خراسان رضوی",
		762: "خراسان رضوی",
		763: "خراسان رضوی",
		764: "خراسان رضوی",
		765: "خراسان رضوی",
		766: "خراسان رضوی",
		767: "خراسان رضوی",
		768: "خراسان رضوی",
		769: "خراسان رضوی",
		771: "خراسان رضوی",
		772: "خراسان رضوی",
		773: "خراسان رضوی",
		774: "خراسان رضوی",
		775: "خراسان رضوی",
		776: "خراسان رضوی",
		777: "خراسان رضوی",
		779: "خراسان شمالی",
		781: "خراسان شمالی",
		791: "خراسان جنوبی",
		792: "خراسان جنوبی",
		812: "کرمان",
		813: "کرمان",
		814: "کرمان",
		815: "کرمان",
		816: "کرمان",
		817: "کرمان",
		819: "سیستان و بلوچستان",
		821: "سیستان و بلوچستان",
		822: "سیستان و بلوچستان",
		823: "سیستان و بلوچستان",
		827: "بوشهر",
		828: "بوشهر",
		829: "بوشهر",
		831: "بوشهر",
		835: "هرمزگان",
		836: "هرمزگان",
		837: "هرمزگان",
		838: "هرمزگان",
		839: "هرمزگان",
	},
	Category: {
		D: "دیپلمات",
		S: "سفارتخانه ها ",
		ف: "ستاد کل نیرو های مسلح",
		ز: "وزارت دفاع و پشتیبانی",
		ش: "ارتش",
		تشریفات: "تشریفات",
		الف: "دولتی",
		پ: "پلیس",
		ث: "سپاه",
		ک: "کشاورزی",
		ع: "حمل و نقل عمومی",
		گ: "گذر موقت",
		ژ: "معلولان و جانبازان",
		ب: "شخصی",
		ج: "شخصی",
		د: "شخصی",
		س: "شخصی",
		ص: "شخصی",
		ط: "شخصی",
		ق: "شخصی",
		ل: "شخصی",
		م: "شخصی",
		ن: "شخصی",
		و: "شخصی",
		ه: "شخصی",
		ی: "شخصی",
	},
};
